import { Box, Grid, SxProps, Theme, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import React, { forwardRef, PropsWithChildren } from 'react';

export type ObjectListContainerProps = {
    label?: JSX.Element;
    open?: boolean;
    sx?: SxProps<Theme>;
};

export const ObjectListContainer = forwardRef<HTMLDivElement, PropsWithChildren<ObjectListContainerProps>>(
    ({ label, open, sx, children }, ref) => (
        <Box
            ref={ref}
            sx={(theme) => ({
                width: '91%',
                border: 'solid 1px',
                borderColor: open ? theme.palette.secondary.main : '#C6C4C4',
                borderRadius: '8px',
                mx: '25px',
                mt: '18px',
                p: 1,
                height: '50px',
                ...(sx as SystemStyleObject<Theme>)
            })}
        >
            <Grid container sx={{ height: '100%' }}>
                {label && (
                    <Grid item xs={4} container alignItems="center" sx={{ height: '100%' }}>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: '13px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                color: '#616161'
                            }}
                        >
                            {label}
                        </Typography>
                    </Grid>
                )}

                <Grid item xs={label ? 8 : 12} sx={{ height: '100%' }}>
                    {children}
                </Grid>
            </Grid>
        </Box>
    )
);
