import React, { createContext, useState, ReactNode } from 'react';

import { v4 as uuidv4 } from 'uuid';

interface DocumentViewerContextProps {
    comment: string;
    lineItem: string;
    handleSetCommentUpdated: () => void;
    handleSetLineItemUpdated: () => void;
}
export const DocumentViewerContext = createContext<DocumentViewerContextProps | undefined>(undefined);

export const DocumentViewerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [comment, setCommentUpdated] = useState<string>('');
    const [lineItem, setLineItemUpdated] = useState<string>('');

    const handleSetCommentUpdated = () => {
        setCommentUpdated(uuidv4());
    };

    const handleSetLineItemUpdated = () => {
        setLineItemUpdated(uuidv4());
    };

    return (
        <DocumentViewerContext.Provider value={{ comment, lineItem, handleSetCommentUpdated, handleSetLineItemUpdated }}>
            {children}
        </DocumentViewerContext.Provider>
    );
};
