import { SvgIconProps } from '@mui/material';

export const CloudSad = (props: SvgIconProps) => (
    <svg width="169" height="110" viewBox="0 0 169 110" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M140.128 102.174H23.6284C5.33667 102.174 -18.6615 57.1777 28.1284 44.6749C28.1284 -4.32416 80.8393 -14.3224 92.1284 21.1749C120.339 12.6776 129.628 32.6704 129.628 39.6744C185.839 37.6749 172.339 102.174 140.128 102.174Z"
            fill="#EBEEFE"
        />
        <circle cx="99.9539" cy="50" r="7" fill="black" />
        <circle cx="69.9539" cy="50" r="7" fill="black" />
        <mask id="path-4-inside-1_1115_1675" fill="white">
            <path d="M98.2825 79.9026C96.6453 78.0988 94.659 76.646 92.4439 75.6324C90.2288 74.6187 87.8309 74.0652 85.3956 74.0054C82.9603 73.9456 80.5382 74.3808 78.276 75.2845C76.0139 76.1883 73.9586 77.5418 72.2349 79.2631L73.0823 80.1117C74.6912 78.505 76.6095 77.2417 78.7209 76.3981C80.8324 75.5546 83.0931 75.1484 85.3662 75.2043C87.6393 75.2601 89.8773 75.7767 91.9449 76.7228C94.0124 77.6689 95.8664 79.0249 97.3945 80.7086L98.2825 79.9026Z" />
        </mask>
        <path
            d="M98.2825 79.9026C96.6453 78.0988 94.659 76.646 92.4439 75.6324C90.2288 74.6187 87.8309 74.0652 85.3956 74.0054C82.9603 73.9456 80.5382 74.3808 78.276 75.2845C76.0139 76.1883 73.9586 77.5418 72.2349 79.2631L73.0823 80.1117C74.6912 78.505 76.6095 77.2417 78.7209 76.3981C80.8324 75.5546 83.0931 75.1484 85.3662 75.2043C87.6393 75.2601 89.8773 75.7767 91.9449 76.7228C94.0124 77.6689 95.8664 79.0249 97.3945 80.7086L98.2825 79.9026Z"
            fill="#D9D9D9"
            stroke="black"
            strokeWidth="2"
            mask="url(#path-4-inside-1_1115_1675)"
        />
    </svg>
);
