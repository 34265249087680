/* eslint-disable no-plusplus */
import { GridColDef } from '@mui/x-data-grid-pro';
import { IRecordFieldsList } from 'ui-component/records/types';
import { DEFAULT_HIDDEN_FIELDS } from '../generateGridState';

/**
 * Return an object with the base fields and additional fields splitted
 * and validated with given not allowed function
 *
 * @param headers {IRecordFieldList}
 * @param headers {IRecordFieldList}
 */
export const splitHeaders = (headers?: IRecordFieldsList, filterNotAllowedFn?: (key: string) => boolean) => {
    const baseFieldHeaders: Partial<IRecordFieldsList> = {};
    const additionalFieldHeaders: Partial<IRecordFieldsList> = {};
    if (headers)
        for (const fieldName in headers) {
            if (Object.prototype.hasOwnProperty.call(headers, fieldName)) {
                const element = headers[fieldName];
                if (!filterNotAllowedFn || filterNotAllowedFn(fieldName))
                    if (Number(element.id) === 0) baseFieldHeaders[fieldName] = headers[fieldName];
                    else additionalFieldHeaders[fieldName] = headers[fieldName];
            }
        }

    return { baseFieldHeaders, additionalFieldHeaders };
};

/**
 * Returns and object with all the changes of the record
 *
 * @param oldData {Object} actual data of the record
 * @param newData {Object} new data of the record
 */
export const generateClearedRecordData = (oldData: Record<string, any>, newData: Record<string, number | boolean | string>) => {
    const clearedData: Record<string, any> = {};

    for (const fieldName in newData) {
        if (Object.prototype.hasOwnProperty.call(newData, fieldName)) {
            const oldValue = oldData[fieldName];
            const newValue = newData[fieldName];
            const newValueExist = verifyNewValue(newValue);
            if ((!oldValue && newValueExist) || (oldValue && String(oldValue) !== String(newValue))) {
                clearedData[fieldName] = newValue;
            }
        }
    }

    return clearedData;
};

/**
 * Check if the new value is valid, returns true
 * when exist and have length in string cases
 *
 * @param newVal
 */
const verifyNewValue = (newVal: any) => {
    // TODO: write some tests for number case here
    // TODO: remove currency
    if (['object', 'boolean', '', 'currency'].includes(typeof newVal)) return true;
    if (typeof newVal === 'number') return newVal > 0;
    if (typeof newVal === 'string') return !!newVal.length;
    return false;
};

/**
 * Returns the ids of list that exists in headers
 *
 * @param headers {IRecordFieldsList}
 */
export const getListIdsFromHeaders = (headers?: Partial<IRecordFieldsList>) => {
    if (!headers || !Object.keys(headers).length) return [];
    const ids: number[] = [];

    for (const key in headers) {
        if (Object.prototype.hasOwnProperty.call(headers, key)) {
            const element = headers[key];
            if (element?.listType?.id) ids.push(Number(element.listType.id));
        }
    }

    return ids;
};

/**
 * Changes the index of an item in an array.
 *
 * @param {any[]} arr - The array to modify.
 * @param {number} oldIndex - The current index of the item.
 * @param {number} newIndex - The new index to move the item to.
 * @return {any[]} The modified array.
 */
function changeArrayItemIndex(arr: any[], oldIndex: number, newIndex: number) {
    if (newIndex >= arr.length) {
        let marker = newIndex - arr.length + 1;
        while (marker--) {
            arr.push(undefined);
        }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
}

/**
 * Sorts the columns based on header names or field names and returns an array of fields.
 *
 * @param {GridColDef[]} columns - The array of GridColDef columns to be sorted.
 * @return {string[]} An array of field names sorted based on header names or field names.
 */
export const getTogglableColumns = (columns: GridColDef[]) => {
    // Needs the columns references to work
    columns.sort((a, b) => {
        // Validate record fields and DataGrid default columns
        const compareA = a.headerName && a.headerName.length > 0 ? a.headerName?.toLowerCase() : a.field.toLowerCase();
        const compareB = b.headerName && b.headerName.length > 0 ? b.headerName?.toLowerCase() : b.field.toLowerCase();
        return compareA.localeCompare(compareB);
    });
    // Search for the action and checkbox column
    const actionColumn = columns.find((column) => column.field === 'actions' && column.headerName === '');
    const checkboxSelectionColumn = columns.find((column) => column.field === '__check__' && column.headerName === 'Checkbox selection');
    // Move them to the firsts positions ignoring the alphabetical order
    if (actionColumn) changeArrayItemIndex(columns, columns.indexOf(actionColumn), 0);
    if (checkboxSelectionColumn) changeArrayItemIndex(columns, columns.indexOf(checkboxSelectionColumn), 1);
    // Return the fields only
    return columns.map((column) => column.field).filter((column) => !DEFAULT_HIDDEN_FIELDS.includes(column));
};
