import { GridEditInputCell, GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { IRecordField, IRecordHeaders } from '../types';
import {
    DateEditCellDropdown,
    DateTimeEditCellDropdown,
    EmailEditCellDropdown,
    ListEditDropdown,
    MultiSelectEditDropdown,
    NumberEditCellDropdown,
    PhoneEditCellDropdown,
    ProblemCodeEditDropdown,
    SingleSelectEditDropdown,
    StatusEditDropdown,
    TargetTenantEditDropdown,
    TimeEditCellDropdown,
    UsersEditDropdown
} from '../dropdowns/EditVariations';
import { CheckboxEditCell } from '../dropdowns/CheckboxEditCell';
import { ListValue } from 'views/backoffice/CustomLists/types';

/**
 * Render the edit cell callback for some predefined fields
 */
export const renderEditCellList: Partial<Record<keyof IRecordHeaders, any>> = {
    statusId: (recordTypeId: number) => (params: GridRenderEditCellParams) =>
        <StatusEditDropdown {...params} recordTypeId={recordTypeId} />,
    problemCodeId: (recordTypeId: number) => (params: GridRenderEditCellParams) =>
        <ProblemCodeEditDropdown {...params} recordTypeId={recordTypeId} />,
    approvedBy: () => (params: GridRenderEditCellParams) => <UsersEditDropdown {...params} />,
    assignedTo: () => (params: GridRenderEditCellParams) => <UsersEditDropdown {...params} />,
    receivedBy: () => (params: GridRenderEditCellParams) => <UsersEditDropdown {...params} />,
    targetTransmissionTenant: (recordTypeId: number) => (params: GridRenderEditCellParams) =>
        <TargetTenantEditDropdown recordTypeId={recordTypeId} {...params} />
};

/**
 * Render the edit input cell based on the field type
 *
 * @param key {String} Field Name
 * @param recordTypeId {Number} Record Type Id
 * @param dataType: {String} Field Data Type
 * @returns
 */
export const getRenderEditCell = (key: string, recordTypeId: number, recordField: IRecordField, listValues?: ListValue[]) => {
    const dataType = recordField.dataType;
    const listId = recordField.listType?.id || null;

    if (Object.keys(renderEditCellList).includes(key)) return renderEditCellList[key as keyof IRecordHeaders](recordTypeId);

    if (dataType === 'date') return (params: GridRenderEditCellParams) => <DateEditCellDropdown {...params} hideLabel />;

    if (dataType === 'datetime') return (params: GridRenderEditCellParams) => <DateTimeEditCellDropdown {...params} hideLabel />;

    if (dataType === 'time') return (params: GridRenderEditCellParams) => <TimeEditCellDropdown {...params} hideLabel />;

    if (dataType === 'currency' || dataType === 'number') {
        return (params: GridRenderEditCellParams) => <NumberEditCellDropdown isCurrency={dataType === 'currency'} {...params} />;
    }

    if (dataType === 'phone') {
        return (params: GridRenderEditCellParams) => <PhoneEditCellDropdown {...params} />;
    }

    if (dataType === 'email') {
        return (params: GridRenderEditCellParams) => <EmailEditCellDropdown {...params} />;
    }

    if (dataType === 'single-select') {
        return (params: GridRenderEditCellParams) => (
            <SingleSelectEditDropdown listId={Number(listId)} listValues={listValues} {...params} />
        );
    }

    if (dataType === 'dropdown' && listId) {
        return (params: GridRenderEditCellParams) => (
            <ListEditDropdown
                listId={Number(listId)}
                listValues={listValues}
                listOfObjects={!!recordField.listType?.objectDefinition}
                {...params}
            />
        );
    }

    if ((dataType === 'multiselect' || dataType === 'multiselect-checkbox') && listId) {
        return (params: GridRenderEditCellParams) => (
            <MultiSelectEditDropdown listId={Number(listId)} listValues={listValues} {...params} />
        );
    }

    if (dataType === 'checkbox') return (params: GridRenderEditCellParams) => <CheckboxEditCell {...params} />;

    return (params: GridRenderEditCellParams) => <GridEditInputCell {...params} />;
};

/**
 * returns the edit input cell based on the field type
 *
 * @param dataType {String} Field Data Type
 * @param listId {String | Number} List Id if is list
 */
export const getRenderEditCellByType = (dataType: string, listId?: string | number, listValues?: ListValue[]) => {
    if (dataType === 'date') return (params: GridRenderEditCellParams) => <DateEditCellDropdown {...params} hideLabel />;
    if (dataType === 'datetime') return (params: GridRenderEditCellParams) => <DateTimeEditCellDropdown {...params} hideLabel />;
    if (dataType === 'time') return (params: GridRenderEditCellParams) => <TimeEditCellDropdown {...params} hideLabel />;
    if (dataType === 'currency' || dataType === 'number')
        return (params: GridRenderEditCellParams) => <NumberEditCellDropdown isCurrency={dataType === 'currency'} {...params} />;
    if (dataType === 'dropdown' && listId)
        return (params: GridRenderEditCellParams) => <ListEditDropdown listId={Number(listId)} listValues={listValues} {...params} />;
    if ((dataType === 'multiselect' || dataType === 'multiselect-checkbox') && listId)
        return (params: GridRenderEditCellParams) => (
            <MultiSelectEditDropdown listId={Number(listId)} listValues={listValues} {...params} />
        );

    if (dataType === 'checkbox') return (params: GridRenderEditCellParams) => <CheckboxEditCell {...params} />;
    return (params: GridRenderEditCellParams) => <GridEditInputCell {...params} />;
};
