import { useEffect, useState } from 'react';
import InputMask from 'ui-component/RecordView/components/InputMaskPhoneCell';

export interface PhoneEditCellProps {
    onChange: (value: string) => void;
    value?: string;
}

export const PhoneEditCell = ({ onChange, value }: PhoneEditCellProps) => {
    const [phone, setPhone] = useState(value || null);

    useEffect(() => {
        value && setPhone(value);
    }, [value]);

    const handleChange = (newValue: string | null) => {
        if (!newValue) return;
        setPhone(newValue);
        onChange(newValue);
    };

    return <InputMask value={phone ?? ''} onChange={(e: any) => handleChange(e.target.value)} />;
};
