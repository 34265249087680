import { forwardRef, useEffect, useImperativeHandle, useState, useMemo, useCallback, MutableRefObject } from 'react';
import { ChevronRight } from '@mui/icons-material';
import {
    GridRowModesModel,
    GridRowModes,
    DataGridPro,
    GridColDef,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    DataGridProProps,
    GridSlotsComponent,
    GridRowOrderChangeParams,
    useGridApiRef,
    GridApiPro,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridRenderCellParams
} from '@mui/x-data-grid-pro';
import { getOnlyUpdatedRows, orderAlphabetical, randomInteger, reorder } from './utils';
import { GridToolbar, GridToolbarProps } from './components';
import { SxProps } from '@mui/system';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { GridActionsButtons } from './components/GridActionsButtons';
import useGetFilterAuditLogsForLineItems from './hooks/useGetFilterAuditLogsForLineItems';
import LineItemHistoryDialog from 'ui-component/records/LineItems/Dialogs/LineItemHistoryDialog';

export type GridWithInlineEditProps = {
    GridComponent?: (props: DataGridProProps) => JSX.Element | null;
    loading: boolean;
    initialRows: readonly Record<string, any>[];
    columns: GridColDef[];
    shouldEdit?: boolean;
    onUpdate: (newRow: Record<string, any>) => Promise<Record<string, any> | undefined>;
    onCreate: (newRow: Record<string, any>) => Promise<Record<string, any> | undefined>;
    disabledReordering?: boolean;
    disabledCheckboxSelection?: boolean;
    fieldToFocus?: string;
    density?: 'standard' | 'comfortable' | 'compact';
    onUpdateOrder?: (newOrder: Record<string, any>[]) => Promise<boolean>;
    gridComponents?: Partial<GridSlotsComponent>;
    autosizeColumns?: boolean;
    pinnedActions?: boolean;
    pinnedActionsRight?: boolean;
    sx?: SxProps;
    showQuickFilter?: boolean;
    handleShowImportDialog?: () => void;
    onClickEdit?: (row: Record<string, any>) => void;
    isPreview?: boolean;
    onChangeEditModel?: (model: GridRowModesModel) => void;
    onClickDelete?: (row: any) => Promise<Record<string, any> | undefined>;
    hideActionsLabel?: boolean;
    requiredFields?: string[];
    showActionsBeforeCheckbox?: boolean;
    onChangeRowSelection?: (selectedRows: GridRowId[]) => void;
    showBulkDelete?: boolean;
    onBulkDelete?: () => void;
    customToolbar?: (props: any) => JSX.Element;
    customToolbarProps?: any;
    setSelectedRows?: (selectedRows: GridRowId[]) => void;
    onDeleteSelected?: (selectedRows: GridRowId[]) => void;
    forceEnabledOnCreation?: boolean;
    handleRowEnter?: (row: any) => Promise<void>;
    handlePostCancel?: () => void;
    recordId?: number;
};

export type GridWithInlineEditRef = {
    handleAddClick: (initialValue?: Record<string, any>) => void;
    apiRef: MutableRefObject<GridApiPro>;
};

/**
 * Grid with Inline Edit
 *
 * This component is used to render a grid with inline edit functionality
 * exposing the add new row functionality to the parent component
 *
 */
const GridWithInlineEdit = forwardRef(
    (
        {
            GridComponent = DataGridPro,
            loading,
            initialRows,
            columns,
            onUpdate,
            onCreate,
            disabledReordering,
            disabledCheckboxSelection,
            fieldToFocus,
            onUpdateOrder,
            gridComponents,
            density = 'standard',
            sx,
            onClickEdit,
            shouldEdit = false,
            autosizeColumns = false,
            pinnedActions = false,
            pinnedActionsRight = false,
            showQuickFilter = false,
            handleShowImportDialog,
            isPreview = false,
            onChangeEditModel,
            onClickDelete,
            hideActionsLabel,
            requiredFields,
            showActionsBeforeCheckbox,
            onChangeRowSelection,
            showBulkDelete,
            onBulkDelete,
            customToolbar,
            customToolbarProps,
            setSelectedRows,
            onDeleteSelected,
            forceEnabledOnCreation = false,
            handleRowEnter,
            handlePostCancel,
            recordId
        }: GridWithInlineEditProps,
        ref
    ) => {
        const storeDispatch = useDispatch();
        const apiRef = useGridApiRef();
        const [isReordering, setIsReordering] = useState(false);
        const [rows, setRows] = useState<GridRowModel[]>([...initialRows].sort((a, b) => a.order - b.order));
        // Used to ensure multiple reordering at once
        const backupRows = [...initialRows].sort((a, b) => a.order - b.order);
        const [reorderedRows, setReorderedRows] = useState([...initialRows].sort((a, b) => a.order - b.order));
        const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
        const [checkboxSelection, setCheckboxSelection] = useState<GridRowId[]>([]);
        const [innerSelectedRows, setInnerSelectedRows] = useState<GridRowId[]>([]);
        const [itemIdHistory, setItemIdHistory] = useState<number | null>(null);
        const [loadingProcessRowReorderingUpdate, setLoadingProcessRowReorderingUpdate] = useState<boolean>(false);
        const tenantId = Number(localStorage.getItem('tenant_id'));

        const { getAuditLogForLineItems, dataLogsLineItems, loadingLogsLineItems } = useGetFilterAuditLogsForLineItems();

        const handleEditClick = useCallback(
            (id: GridRowId) => () => {
                setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
            },
            [rowModesModel]
        );

        const handleSaveClick = useCallback(
            (id: GridRowId) => () => {
                setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
            },
            [rowModesModel]
        );

        const handleCancelClick = useCallback(
            (id: GridRowId) => () => {
                setRowModesModel({
                    ...rowModesModel,
                    [id]: { mode: GridRowModes.View, ignoreModifications: true }
                });

                const editedRow = rows.find((row) => row.id === id);
                if (editedRow?.isNew) {
                    setRows(rows.filter((row) => row.id !== id));
                }
                handlePostCancel?.();
            },
            [handlePostCancel, rowModesModel, rows]
        );

        const handleItemHistory = (id: number) => {
            setItemIdHistory(id);
        };

        const handleAddClick = (initialValue?: Record<string, any>) => {
            const id = randomInteger();
            let editableFields =
                initialValue || Object.keys(columns.filter((column) => column.editable)).reduce((acc, key) => ({ ...acc, [key]: '' }), {});
            if (forceEnabledOnCreation && !initialValue) editableFields = { ...editableFields, enabled: true };
            setRows((oldRows) => [{ id, isNew: true, ...editableFields }, ...oldRows]);
            setRowModesModel((oldModel) => ({
                [id]: { mode: GridRowModes.Edit, fieldToFocus },
                ...oldModel
            }));
        };

        const processRowUpdate = async (newRow: GridRowModel) => {
            let updatedRow: Record<string, any> | undefined = { ...newRow };
            if (updatedRow.isNew) {
                updatedRow.isNew = false;
                updatedRow = await onCreate(updatedRow);
            } else {
                updatedRow = await onUpdate(updatedRow);
            }

            if (updatedRow) {
                if (updatedRow.error && updatedRow.error >= 400) {
                    setRowModesModel({ ...rowModesModel, [updatedRow.id]: { mode: GridRowModes.Edit } });
                    return undefined;
                }
                setRows(rows.map((row) => (row.id === newRow.id ? (updatedRow as Record<string, any>) : row)));

                return updatedRow;
            }
            throw new Error('Error updating row');
        };

        const processRowReorderingUpdate = async () => {
            try {
                const onlyUpdatedRows = getOnlyUpdatedRows(backupRows, reorderedRows);

                if (onUpdateOrder) {
                    setLoadingProcessRowReorderingUpdate(true);
                    const success = await onUpdateOrder(onlyUpdatedRows);
                    if (success) {
                        setRows(reorderedRows);
                        setIsReordering(false);
                    } else throw new Error(`Reordering Failed!`);
                    setLoadingProcessRowReorderingUpdate(false);
                    storeDispatch(
                        openSnackbar({
                            open: true,
                            message: 'Reordering Saved!',
                            variant: 'alert',
                            alert: { color: 'success' },
                            close: false
                        })
                    );
                }
            } catch (error) {
                storeDispatch(
                    openSnackbar({
                        open: true,
                        message: (error as Error).message,
                        variant: 'alert',
                        alert: { color: 'warning' },
                        close: false
                    })
                );
            }
        };

        const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
            event.defaultMuiPrevented = true;
        };

        const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
            event.defaultMuiPrevented = true;
        };

        const handleRowReorderChange = ({ targetIndex, oldIndex }: GridRowOrderChangeParams) => {
            const updatedOrder = reorder<Record<string, any>>(rows, oldIndex, targetIndex);
            setReorderedRows(updatedOrder.map((row, index) => ({ ...row, order: index + 1 })));
            // Allows multiple edit order at once
            setRows(updatedOrder.map((row, index) => ({ ...row, order: index + 1 })));
        };

        const handleRowAlphabeticalOrder = () => {
            const updatedOrder = orderAlphabetical(rows, fieldToFocus || 'value');
            setReorderedRows(updatedOrder);
            apiRef.current.setRows(updatedOrder);
        };

        const allColumns: GridColDef[] = useMemo(() => {
            const defaultColumns = [...columns];
            if (showActionsBeforeCheckbox) {
                defaultColumns.unshift(GRID_CHECKBOX_SELECTION_COL_DEF);
            }

            if (shouldEdit)
                defaultColumns.unshift({
                    field: 'actions',
                    type: 'actions',
                    renderHeader: () => (hideActionsLabel ? null : 'Actions'),
                    width: 100,
                    minWidth: 100,
                    cellClassName: 'actions',
                    renderCell: ({ id, row }) => (
                        <GridActionsButtons
                            id={id}
                            row={row}
                            rowModesModel={rowModesModel}
                            handleSaveClick={handleSaveClick}
                            handleCancelClick={handleCancelClick}
                            handleEditClick={handleEditClick}
                            rows={rows}
                            setRows={setRows}
                            onClickDelete={onClickDelete}
                            onClickEdit={onClickEdit}
                            onHistoryClick={handleItemHistory}
                            isPreview={isPreview}
                            requiredFields={requiredFields}
                        />
                    )
                });

            if (handleRowEnter) {
                defaultColumns.push({
                    field: 'enterRow',
                    type: 'actions',
                    headerName: '',
                    width: 100,
                    cellClassName: 'actions',
                    editable: false,
                    renderCell: (params: GridRenderCellParams<any, any>) => {
                        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
                        return (
                            <GridActionsCellItem
                                data-testid="enter-button"
                                icon={<ChevronRight />}
                                label="Enter"
                                color="inherit"
                                onClick={() => {
                                    if (!isInEditMode) handleRowEnter(params.row);
                                    else
                                        storeDispatch(
                                            openSnackbar({
                                                open: true,
                                                message: `Warning`,
                                                subtitle: `Please save first`,
                                                variant: 'alert',
                                                alert: {
                                                    color: 'warning'
                                                },
                                                close: false
                                            })
                                        );
                                }}
                            />
                        );
                    }
                });
            }
            return defaultColumns;
        }, [
            columns,
            showActionsBeforeCheckbox,
            shouldEdit,
            handleRowEnter,
            hideActionsLabel,
            rowModesModel,
            isPreview,
            onClickDelete,
            requiredFields,
            handleSaveClick,
            handleCancelClick,
            onClickEdit,
            handleEditClick,
            rows,
            storeDispatch
        ]);

        useImperativeHandle(ref, () => ({
            handleAddClick,
            apiRef
        }));

        // Needed to autosize columns after the edit mode change
        useEffect(() => {
            if (autosizeColumns && apiRef.current && rowModesModel)
                apiRef.current.autosizeColumns({
                    includeHeaders: true,
                    includeOutliers: true
                });
        }, [apiRef, autosizeColumns, rowModesModel]);

        useEffect(() => {
            if (initialRows) {
                setRows([...initialRows].sort((a, b) => a.order - b.order).map((row, idx) => ({ ...row, order: idx + 1 })));
                setReorderedRows([...initialRows].sort((a, b) => a.order - b.order).map((row, idx) => ({ ...row, order: idx + 1 })));

                if (autosizeColumns || !loading)
                    apiRef.current.autosizeColumns({
                        includeHeaders: true,
                        includeOutliers: true
                    });
            }
        }, [initialRows, apiRef, autosizeColumns, loading]);

        useEffect(() => {
            if (onChangeEditModel) onChangeEditModel(rowModesModel);
        }, [onChangeEditModel, rowModesModel]);

        useEffect(() => {
            if (itemIdHistory && tenantId && recordId)
                getAuditLogForLineItems({
                    variables: {
                        data: {
                            filterInput: {
                                recordId,
                                tenantId
                            },
                            pagination: { limit: 25, offset: 0 }
                        }
                    }
                });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [itemIdHistory]);

        return (
            <>
                <GridComponent
                    data-testid="grid-with-inline-edit"
                    apiRef={apiRef}
                    loading={loading || loadingProcessRowReorderingUpdate}
                    rows={rows}
                    columns={allColumns}
                    editMode="row"
                    density={density}
                    rowModesModel={rowModesModel}
                    onRowEditStart={handleRowEditStart}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    initialState={{
                        pinnedColumns: {
                            left: pinnedActions ? ['actions'] : [],
                            right: pinnedActionsRight ? ['actionsRight', 'enterRow'] : []
                        }
                    }}
                    components={{ ...gridComponents, Toolbar: !isPreview ? customToolbar ?? GridToolbar : undefined }}
                    componentsProps={{
                        toolbar: !isPreview
                            ? customToolbarProps ??
                              ({
                                  ToolbarButtons: gridComponents?.Toolbar,
                                  onAlphabeticalOrder: handleRowAlphabeticalOrder,
                                  rowReorder: !disabledReordering,
                                  onSaveReorder: processRowReorderingUpdate,
                                  toggleReorder: () => {
                                      if (isReordering) apiRef.current.setRows(rows);
                                      setIsReordering(!isReordering);
                                      setReorderedRows(rows);
                                  },
                                  isReordering,
                                  handleClickDelete: showBulkDelete && checkboxSelection.length && onBulkDelete ? onBulkDelete : undefined,
                                  showQuickFilter,
                                  handleShowImportDialog,
                                  selectedRows: innerSelectedRows,
                                  onDeleteSelected,
                                  shouldEdit
                              } as GridToolbarProps)
                            : undefined
                    }}
                    // used to ensure that when the grid doesn't have any rows but the user is creating a new one, headers will show
                    sx={
                        rows.length > 0
                            ? {
                                  ...sx,
                                  '& .MuiDataGrid-toolbarContainer, .MuiDataGrid-columnHeaders': { display: 'flex' },
                                  '& .Mui-error': {
                                      border: '1px solid red',
                                      height: '100%',
                                      color: '#FF0000'
                                  }
                              }
                            : sx
                    }
                    onProcessRowUpdateError={(error) => console.log('error', error)}
                    getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                    checkboxSelection={!disabledCheckboxSelection}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setCheckboxSelection(newRowSelectionModel);
                        onChangeRowSelection?.(newRowSelectionModel);
                        setInnerSelectedRows(newRowSelectionModel);
                        setSelectedRows?.(newRowSelectionModel);
                    }}
                    rowSelectionModel={checkboxSelection}
                    disableRowSelectionOnClick
                    pagination
                    rowReordering={!disabledReordering && isReordering}
                    onRowOrderChange={handleRowReorderChange}
                />

                {!!itemIdHistory && (
                    <LineItemHistoryDialog
                        lineItemId={itemIdHistory}
                        items={dataLogsLineItems}
                        open={!!itemIdHistory}
                        onClose={() => setItemIdHistory(null)}
                        onFetchMore={() => {}}
                        total={0}
                        logReportMode=""
                        loading={loadingLogsLineItems}
                    />
                )}
            </>
        );
    }
);

export default GridWithInlineEdit;
