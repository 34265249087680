import { SvgIcon, SvgIconProps } from '@mui/material';

export const Export = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <mask id="mask0_2715_39511" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                <rect width="16" height="16" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2715_39511)">
                <path
                    d="M2.66634 13.3332C2.29967 13.3332 1.9859 13.2027 1.72501 12.9418C1.46367 12.6805 1.33301 12.3665 1.33301 11.9998V3.99984C1.33301 3.63317 1.46367 3.31939 1.72501 3.0585C1.9859 2.79717 2.29967 2.6665 2.66634 2.6665H7.99967V3.99984H2.66634V11.9998H13.333V10.6665H14.6663V11.9998C14.6663 12.3665 14.5359 12.6805 14.275 12.9418C14.0137 13.2027 13.6997 13.3332 13.333 13.3332H2.66634Z"
                    fill="#858585"
                />
                <path
                    d="M10.2813 8.26465L9.10196 7.08535L11.8765 4.31085L9.90109 4.42883L9.99121 2.6582L15.008 2.3586L14.7084 7.3754L12.9359 7.49687L13.0558 5.49015L10.2813 8.26465Z"
                    fill="#858585"
                />
            </g>
        </svg>
    </SvgIcon>
);
