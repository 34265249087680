import { SvgIcon, SvgIconProps } from '@mui/material';

export const FilePdf = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <mask id="mask0_2715_51371" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2715_51371)">
                <path
                    d="M5 23.5405C4.45 23.5405 3.97933 23.32 3.588 22.879C3.196 22.4372 3 21.9064 3 21.2865V3.25405C3 2.63419 3.196 2.10336 3.588 1.66156C3.97933 1.22052 4.45 1 5 1H15L21 7.76216V21.2865C21 21.9064 20.8043 22.4372 20.413 22.879C20.021 23.32 19.55 23.5405 19 23.5405H5ZM14 8.88919V3.25405H5V21.2865H19V8.88919H14Z"
                    fill="#858585"
                />
                <path
                    d="M7 18V17V18ZM7 17V15H8C8.26522 15 8.51957 15.1054 8.70711 15.2929C8.89464 15.4804 9 15.7348 9 16C9 16.2652 8.89464 16.5196 8.70711 16.7071C8.51957 16.8946 8.26522 17 8 17H7ZM11 18V15H11.5C11.8978 15 12.2794 15.158 12.5607 15.4393C12.842 15.7206 13 16.1022 13 16.5C13 16.8978 12.842 17.2794 12.5607 17.5607C12.2794 17.842 11.8978 18 11.5 18H11ZM15 18V16.75V18ZM16.5 15H15V16.75M15 16.75H16.5H15Z"
                    fill="#858585"
                />
                <path
                    d="M7 18V17M7 17V15H8C8.26522 15 8.51957 15.1054 8.70711 15.2929C8.89464 15.4804 9 15.7348 9 16C9 16.2652 8.89464 16.5196 8.70711 16.7071C8.51957 16.8946 8.26522 17 8 17H7ZM15 18V16.75M15 16.75V15H16.5M15 16.75H16.5M11 18V15H11.5C11.8978 15 12.2794 15.158 12.5607 15.4393C12.842 15.7206 13 16.1022 13 16.5C13 16.8978 12.842 17.2794 12.5607 17.5607C12.2794 17.842 11.8978 18 11.5 18H11Z"
                    stroke="#858585"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    </SvgIcon>
);
