import { useContext } from 'react';
import { DocumentViewerContext } from '../context/DocumentViewerProvider';

export const useDocumentViewer = () => {
    const context = useContext(DocumentViewerContext);
    if (!context) {
        throw new Error('useDocumentViewer must be used within a DocumentViewerProvider');
    }
    return context;
};
