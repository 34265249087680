import { createSlice } from '@reduxjs/toolkit';
import { DataFocusViewStore } from 'views/DataFocusView/types';

// initial state
const initialState: DataFocusViewStore = {
    selected: undefined,
    defaultPreference: undefined,
    list: []
};

// ==============================|| SLICE - DASHBOARD ||============================== //

const dataFocusView = createSlice({
    name: 'dataFocusView',
    initialState,
    reducers: {
        setDataFocusViewList(state, action) {
            state.list = action.payload;
        },

        setSelectedDataFocusView(state, action) {
            state.selected = action.payload;
        },

        setDefaultPreference(state, action) {
            state.defaultPreference = action.payload;
        }
    }
});

export default dataFocusView.reducer;

export const { setDataFocusViewList, setSelectedDataFocusView, setDefaultPreference } = dataFocusView.actions;
