import React, { forwardRef, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { Box, TextField, TextFieldProps } from '@mui/material';
import { GridSingleSelect } from 'ui-component/formComponents';
import { ArrowDropDown } from '@mui/icons-material';
import { phoneCountryOptions } from 'views/Portal/Tabs/Request/assets';

export interface CustomProps {
    inputRef: React.Ref<HTMLInputElement>;
    onChange: (...event: any[]) => void;
    name: string;
    disabled: boolean;
}

export interface EventTarget {
    target: {
        name: string;
        value: string;
    };
}

const TextMaskCustom = forwardRef<HTMLElement, CustomProps>((props, ref) => {
    const { onChange, ...other } = props;

    return (
        <IMaskInput
            {...other}
            mask="(#00) 000-0000"
            definitions={{
                '#': /[1-9]/
            }}
            inputRef={ref as React.Ref<HTMLInputElement>}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

export interface InputMaskProps extends Omit<TextFieldProps, 'onChange'> {
    name?: string;
    onChange: (...event: any[]) => void;
    value: string;
    disabled?: boolean;
    options?: {
        id: string;
        key: string;
        name: string;
        renderName: JSX.Element;
        valuesToFilter: string[];
    }[];
    widthCountrySelector?: string;
}

const SEPARATOR = ',';

const InputMaskPhone: React.FC<InputMaskProps> = (props) => {
    const eventTargetRef = React.useRef<EventTarget>({} as EventTarget);

    const { name, onChange, options, value, widthCountrySelector, disabled, ...other } = props;
    // eslint-disable-next-line no-useless-escape
    const valueWithoutBrackets = value.replace(/[\[\]]/g, '');

    const [selectedCountry, setSelectedCountry] = useState<string | number>(
        valueWithoutBrackets.includes(SEPARATOR) ? valueWithoutBrackets.split(SEPARATOR)[0] : ''
    );

    const handleCountryChange = (valuePhone: string | number) => {
        const valuePhoneStr = String(valuePhone);
        const valuePhoneWithoutPlus = valuePhoneStr.replace(/\+/g, '');

        if (Object.keys(eventTargetRef.current).length > 0 && value !== selectedCountry) {
            const beforeValue = eventTargetRef.current.target.value.includes(SEPARATOR)
                ? eventTargetRef.current.target.value.split(SEPARATOR)[1]
                : eventTargetRef.current.target.value;
            // eslint-disable-next-line no-useless-escape
            const beforeValueWithoutBrackets = beforeValue.replace(/[\[\]]/g, '');

            onChange({
                target: {
                    ...eventTargetRef.current.target,
                    value: `[${valuePhoneWithoutPlus}${SEPARATOR}${beforeValueWithoutBrackets}]`
                }
            });
        }

        setSelectedCountry(valuePhoneWithoutPlus);
    };

    const handleInputChange = (event: EventTarget) => {
        const newValue = event.target.value.includes(SEPARATOR) ? event.target.value.split(SEPARATOR)[1] : event.target.value;
        const cleanedValue = newValue.replace(/[()\-\s]/g, '');

        if (cleanedValue.length > 0) {
            event.target = {
                ...event.target,
                value: `[${selectedCountry}${SEPARATOR}${cleanedValue}]`
            };
            eventTargetRef.current = event;
        }
        onChange(event);
    };

    return (
        <Box display="flex" alignItems="center" justifyItems="center" sx={{ bgcolor: '#F5F6F7', borderRadius: 2 }}>
            <GridSingleSelect
                width={widthCountrySelector || '8%'}
                onChange={handleCountryChange}
                options={options || phoneCountryOptions}
                listFitContent
                value={`+${selectedCountry}`}
                disabled={disabled}
                leftIcon={<ArrowDropDown />}
                sx={{ bgcolor: '#F5F6F7', height: 35, borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}
            />
            <TextField
                {...other}
                name={name}
                disabled={disabled}
                onChange={handleInputChange}
                value={valueWithoutBrackets.includes(SEPARATOR) ? valueWithoutBrackets.split(SEPARATOR)[1] : valueWithoutBrackets}
                InputProps={{
                    inputComponent: TextMaskCustom as any
                }}
            />
        </Box>
    );
};

export default InputMaskPhone;
