import { Box, Typography } from '@mui/material';
import { AxiosRequestConfig } from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import CountryList from 'country-list-with-dial-code-and-flag';
// eslint-disable-next-line import/no-extraneous-dependencies
import CountryFlagSvg from 'country-list-with-dial-code-and-flag/dist/flag-svg';
import { Level } from 'ui-component/navigation';
import * as yup from 'yup';

export const LEVELS: Level[] = [{ title: 'portalOrder' }, { title: 'AIS' }];

export const onlyCommercialOrderSchemaWithSPANo = yup.object({
    Opportunity: yup.object({
        'Dealer ID': yup.string().required()
    }),
    'Order Information': yup.object({
        'PO Number': yup.string().required(),
        'Order Contact First Name': yup.string().required(),
        'Order Contact Last Name': yup.string().required(),
        'Order Contact Country Code': yup.string().required(),
        'Order Contact Phone': yup.string().required(),
        'Order Contact Email': yup.string().required(),
        'Dealer Sales Person Country Code': yup.string().required(),
        'Dealer Sales Person Phone': yup.string().required(),
        'Dealer Sales Person First Name': yup.string().required(),
        'Dealer Sales Person Last Name': yup.string().required(),
        'Dealer Sales Person Email': yup.string().required()
    }),
    'Pricing Program': yup.string().required(),
    'Shipping Information': yup.object({
        City: yup.string().required(),
        State: yup.string().required(),
        Country: yup.string().required(),
        'Company Name': yup.string().required(),
        'Address 1': yup.string().required(),
        'Postal Code': yup.string().required(),
        'Delivery Contact 1 First Name': yup.string().required(),
        'Delivery Contact 1 Last Name': yup.string().required(),
        'Delivery Contact 1 Country Code': yup.string().required(),
        'Delivery Contact 1 Phone': yup.string().required(),
        'Delivery Contact 1 Email': yup.string().required(),
        'Delivery Contact 2 First Name': yup.string().required(),
        'Delivery Contact 2 Last Name': yup.string().required(),
        'Delivery Contact 2 Country Code': yup.string().required(),
        'Delivery Contact 2 Phone': yup.string().required(),
        'Delivery Contact 2 Email': yup.string().required(),
        'Destination Type': yup.string().required(),
        'Docks Open on the following Days': yup.array()
    })
});

export const onlyCommercialOrderSchemaWithSPAExpired = yup.object({
    Opportunity: yup.object({
        'Dealer ID': yup.string().required()
    }),
    'Order Information': yup.object({
        'PO Number': yup.string().required(),
        'Order Contact First Name': yup.string().required(),
        'Order Contact Last Name': yup.string().required(),
        'Order Contact Country Code': yup.string().required(),
        'Order Contact Phone': yup.string().required(),
        'Order Contact Email': yup.string().required(),
        'Dealer Sales Person Country Code': yup.string().required(),
        'Dealer Sales Person Phone': yup.string().required(),
        'Dealer Sales Person First Name': yup.string().required(),
        'Dealer Sales Person Last Name': yup.string().required(),
        'Dealer Sales Person Email': yup.string().required()
    }),
    'Shipping Information': yup.object({
        City: yup.string().required(),
        State: yup.string().required(),
        Country: yup.string().required(),
        'Company Name': yup.string().required(),
        'Address 1': yup.string().required(),
        'Postal Code': yup.string().required(),
        'Delivery Contact 1 First Name': yup.string().required(),
        'Delivery Contact 1 Last Name': yup.string().required(),
        'Delivery Contact 1 Country Code': yup.string().required(),
        'Delivery Contact 1 Phone': yup.string().required(),
        'Delivery Contact 1 Email': yup.string().required(),
        'Delivery Contact 2 First Name': yup.string().required(),
        'Delivery Contact 2 Last Name': yup.string().required(),
        'Delivery Contact 2 Country Code': yup.string().required(),
        'Delivery Contact 2 Phone': yup.string().required(),
        'Delivery Contact 2 Email': yup.string().required(),
        'Destination Type': yup.string().required(),
        'Docks Open on the following Days': yup.array()
    })
});

export const onlyCommercialOrderSchemaWithSPANoAndContractName = yup.object({
    Opportunity: yup.object({
        'Dealer ID': yup.string().required()
    }),
    'Pricing Program Contract Name': yup.string().required(),
    'Order Information': yup.object({
        'PO Number': yup.string().required(),
        'Order Contact First Name': yup.string().required(),
        'Order Contact Last Name': yup.string().required(),
        'Order Contact Country Code': yup.string().required(),
        'Order Contact Phone': yup.string().required(),
        'Order Contact Email': yup.string().required(),
        'Dealer Sales Person Country Code': yup.string().required(),
        'Dealer Sales Person Phone': yup.string().required(),
        'Dealer Sales Person First Name': yup.string().required(),
        'Dealer Sales Person Last Name': yup.string().required(),
        'Dealer Sales Person Email': yup.string().required()
    }),
    'Shipping Information': yup.object({
        City: yup.string().required(),
        State: yup.string().required(),
        Country: yup.string().required(),
        'Company Name': yup.string().required(),
        'Address 1': yup.string().required(),
        'Postal Code': yup.string().required(),
        'Delivery Contact 1 First Name': yup.string().required(),
        'Delivery Contact 1 Last Name': yup.string().required(),
        'Delivery Contact 1 Country Code': yup.string().required(),
        'Delivery Contact 1 Phone': yup.string().required(),
        'Delivery Contact 1 Email': yup.string().required(),
        'Delivery Contact 2 First Name': yup.string().required(),
        'Delivery Contact 2 Last Name': yup.string().required(),
        'Delivery Contact 2 Country Code': yup.string().required(),
        'Delivery Contact 2 Phone': yup.string().required(),
        'Delivery Contact 2 Email': yup.string().required(),
        'Destination Type': yup.string().required(),
        'Docks Open on the following Days': yup.array()
    })
});

export const onlyCommercialOrderSchemaWithSPAYes = yup.object({
    Opportunity: yup.object({
        'Dealer ID': yup.string().required()
    }),
    'Order Information': yup.object({
        'PO Number': yup.string().required(),
        'Order Contact First Name': yup.string().required(),
        'Order Contact Last Name': yup.string().required(),
        'Order Contact Country Code': yup.string().required(),
        'Order Contact Phone': yup.string().required(),
        'Order Contact Email': yup.string().required(),
        'Dealer Sales Person Country Code': yup.string().required(),
        'Dealer Sales Person Phone': yup.string().required(),
        'Dealer Sales Person First Name': yup.string().required(),
        'Dealer Sales Person Last Name': yup.string().required(),
        'Dealer Sales Person Email': yup.string().required()
    }),
    'Shipping Information': yup.object({
        City: yup.string().required(),
        State: yup.string().required(),
        Country: yup.string().required(),
        'Company Name': yup.string().required(),
        'Address 1': yup.string().required(),
        'Postal Code': yup.string().required(),
        'Delivery Contact 1 First Name': yup.string().required(),
        'Delivery Contact 1 Country Code': yup.string().required(),
        'Delivery Contact 1 Phone': yup.string().required(),
        'Delivery Contact 1 Email': yup.string().required(),
        'Delivery Contact 2 First Name': yup.string().required(),
        'Delivery Contact 2 Country Code': yup.string().required(),
        'Delivery Contact 2 Phone': yup.string().required(),
        'Delivery Contact 2 Email': yup.string().required(),
        'Destination Type': yup.string().required(),
        'Docks Open on the following Days': yup.array()
    })
});

export const commercialOrderWithAddOnSchema = yup.object({
    'Order Information': yup.object({
        'PO Number': yup.string().required(),
        'Order Contact First Name': yup.string().required(),
        'Order Contact Last Name': yup.string().required(),
        'Order Contact Country Code': yup.string().required(),
        'Order Contact Phone': yup.string().required(),
        'Order Contact Email': yup.string().required(),
        'Dealer Sales Person Country Code': yup.string().required(),
        'Dealer Sales Person Phone': yup.string().required(),
        'Dealer Sales Person First Name': yup.string().required(),
        'Dealer Sales Person Last Name': yup.string().required(),
        'Dealer Sales Person Email': yup.string().required()
    }),
    'Shipping Information': yup.object({
        City: yup.string().required(),
        State: yup.string().required(),
        Country: yup.string().required(),
        'Company Name': yup.string().required(),
        'Address 1': yup.string().required(),
        'Postal Code': yup.string().required(),
        'Delivery Contact 1 First Name': yup.string().required(),
        'Delivery Contact 1 Country Code': yup.string().required(),
        'Delivery Contact 1 Phone': yup.string().required(),
        'Delivery Contact 1 Email': yup.string().required(),
        'Delivery Contact 2 First Name': yup.string().required(),
        'Delivery Contact 2 Country Code': yup.string().required(),
        'Delivery Contact 2 Phone': yup.string().required(),
        'Delivery Contact 2 Email': yup.string().required(),
        'Destination Type': yup.string().required(),
        'Docks Open on the following Days': yup.array()
    }),
    'Sales Order': yup.object({
        'Original Sales Order #': yup.string().required()
    })
});

export const commercialOrderWithPunchSchemaSPANo = yup.object({
    'Pricing Program': yup.string().required(),
    'Order Information': yup.object({
        'PO Number': yup.string().required(),
        'Order Contact First Name': yup.string().required(),
        'Order Contact Last Name': yup.string().required(),
        'Order Contact Country Code': yup.string().required(),
        'Order Contact Phone': yup.string().required(),
        'Order Contact Email': yup.string().required(),
        'Dealer Sales Person Country Code': yup.string().required(),
        'Dealer Sales Person Phone': yup.string().required(),
        'Dealer Sales Person First Name': yup.string().required(),
        'Dealer Sales Person Last Name': yup.string().required(),
        'Dealer Sales Person Email': yup.string().required()
    }),
    'Shipping Information': yup.object({
        City: yup.string().required(),
        State: yup.string().required(),
        Country: yup.string().required(),
        'Company Name': yup.string().required(),
        'Address 1': yup.string().required(),
        'Postal Code': yup.string().required(),
        'Delivery Contact 1 First Name': yup.string().required(),
        'Delivery Contact 1 Country Code': yup.string().required(),
        'Delivery Contact 1 Phone': yup.string().required(),
        'Delivery Contact 1 Email': yup.string().required(),
        'Delivery Contact 2 First Name': yup.string().required(),
        'Delivery Contact 2 Country Code': yup.string().required(),
        'Delivery Contact 2 Phone': yup.string().required(),
        'Delivery Contact 2 Email': yup.string().required(),
        'Destination Type': yup.string().required(),
        'Docks Open on the following Days': yup.array()
    }),
    'Sales Order': yup.object({
        'Original Sales Order #': yup.string().required()
    })
});

export const commercialOrderWithPunchSchema = yup.object({
    'Order Information': yup.object({
        'PO Number': yup.string().required(),
        'Order Contact First Name': yup.string().required(),
        'Order Contact Last Name': yup.string().required(),
        'Order Contact Country Code': yup.string().required(),
        'Order Contact Phone': yup.string().required(),
        'Order Contact Email': yup.string().required(),
        'Dealer Sales Person Country Code': yup.string().required(),
        'Dealer Sales Person Phone': yup.string().required(),
        'Dealer Sales Person First Name': yup.string().required(),
        'Dealer Sales Person Last Name': yup.string().required(),
        'Dealer Sales Person Email': yup.string().required()
    }),
    'Shipping Information': yup.object({
        City: yup.string().required(),
        State: yup.string().required(),
        Country: yup.string().required(),
        'Company Name': yup.string().required(),
        'Address 1': yup.string().required(),
        'Postal Code': yup.string().required(),
        'Delivery Contact 1 First Name': yup.string().required(),
        'Delivery Contact 1 Country Code': yup.string().required(),
        'Delivery Contact 1 Phone': yup.string().required(),
        'Delivery Contact 1 Email': yup.string().required(),
        'Delivery Contact 2 First Name': yup.string().required(),
        'Delivery Contact 2 Country Code': yup.string().required(),
        'Delivery Contact 2 Phone': yup.string().required(),
        'Delivery Contact 2 Email': yup.string().required(),
        'Destination Type': yup.string().required(),
        'Docks Open on the following Days': yup.array()
    }),
    'Sales Order': yup.object({
        'Original Sales Order #': yup.string().required()
    })
});

export const commercialOrderWithShowroomSchemaSPANo = yup.object({
    Opportunity: yup.object({
        'Dealer ID': yup.string().required()
    }),
    'Order Information': yup.object({
        'PO Number': yup.string().required(),
        'Order Contact First Name': yup.string().required(),
        'Order Contact Last Name': yup.string().required(),
        'Order Contact Country Code': yup.string().required(),
        'Order Contact Phone': yup.string().required(),
        'Order Contact Email': yup.string().required(),
        'Dealer Sales Person Country Code': yup.string().required(),
        'Dealer Sales Person Phone': yup.string().required(),
        'Dealer Sales Person First Name': yup.string().required(),
        'Dealer Sales Person Last Name': yup.string().required(),
        'Dealer Sales Person Email': yup.string().required()
    }),
    'Shipping Information': yup.object({
        City: yup.string().required(),
        State: yup.string().required(),
        Country: yup.string().required(),
        'Company Name': yup.string().required(),
        'Address 1': yup.string().required(),
        'Postal Code': yup.string().required(),
        'Delivery Contact 1 First Name': yup.string().required(),
        'Delivery Contact 1 Country Code': yup.string().required(),
        'Delivery Contact 1 Phone': yup.string().required(),
        'Delivery Contact 1 Email': yup.string().required(),
        'Delivery Contact 2 First Name': yup.string().required(),
        'Delivery Contact 2 Country Code': yup.string().required(),
        'Delivery Contact 2 Phone': yup.string().required(),
        'Delivery Contact 2 Email': yup.string().required(),
        'Destination Type': yup.string().required(),
        'Docks Open on the following Days': yup.array()
    })
});

export const commercialOrderWithShowroomSchemaSPAYesExpired = yup.object({
    Opportunity: yup.object({
        'Dealer ID': yup.string().required()
    }),
    'Order Information': yup.object({
        'PO Number': yup.string().required(),
        'Order Contact First Name': yup.string().required(),
        'Order Contact Last Name': yup.string().required(),
        'Order Contact Country Code': yup.string().required(),
        'Order Contact Phone': yup.string().required(),
        'Order Contact Email': yup.string().required(),
        'Dealer Sales Person Country Code': yup.string().required(),
        'Dealer Sales Person Phone': yup.string().required(),
        'Dealer Sales Person First Name': yup.string().required(),
        'Dealer Sales Person Last Name': yup.string().required(),
        'Dealer Sales Person Email': yup.string().required()
    }),
    'Shipping Information': yup.object({
        City: yup.string().required(),
        State: yup.string().required(),
        Country: yup.string().required(),
        'Company Name': yup.string().required(),
        'Address 1': yup.string().required(),
        'Postal Code': yup.string().required(),
        'Delivery Contact 1 First Name': yup.string().required(),
        'Delivery Contact 1 Country Code': yup.string().required(),
        'Delivery Contact 1 Phone': yup.string().required(),
        'Delivery Contact 1 Email': yup.string().required(),
        'Delivery Contact 2 First Name': yup.string().required(),
        'Delivery Contact 2 Country Code': yup.string().required(),
        'Delivery Contact 2 Phone': yup.string().required(),
        'Delivery Contact 2 Email': yup.string().required(),
        'Destination Type': yup.string().required(),
        'Docks Open on the following Days': yup.array()
    })
});

export const onlyGovernmentSchemaSPANo = yup.object({
    'Discounting Programs Type': yup.string().required(),
    Billing: yup.string().required(),
    Opportunity: yup.object({
        'Dealer ID': yup.string().required()
    }),
    'Order Information': yup.object({
        'PO Number': yup.string().required(),
        'Order Contact First Name': yup.string().required(),
        'Order Contact Last Name': yup.string().required(),
        'Order Contact Country Code': yup.string().required(),
        'Order Contact Phone': yup.string().required(),
        'Order Contact Email': yup.string().required(),
        'Dealer Sales Person Country Code': yup.string().required(),
        'Dealer Sales Person Phone': yup.string().required(),
        'Dealer Sales Person First Name': yup.string().required(),
        'Dealer Sales Person Last Name': yup.string().required(),
        'Dealer Sales Person Email': yup.string().required()
    }),
    'Shipping Information': yup.object({
        City: yup.string().required(),
        State: yup.string().required(),
        Country: yup.string().required(),
        'Company Name': yup.string().required(),
        'Address 1': yup.string().required(),
        'Postal Code': yup.string().required(),
        'Delivery Contact 1 First Name': yup.string().required(),
        'Delivery Contact 1 Country Code': yup.string().required(),
        'Delivery Contact 1 Phone': yup.string().required(),
        'Delivery Contact 1 Email': yup.string().required(),
        'Delivery Contact 2 First Name': yup.string().required(),
        'Delivery Contact 2 Country Code': yup.string().required(),
        'Delivery Contact 2 Phone': yup.string().required(),
        'Delivery Contact 2 Email': yup.string().required(),
        'Destination Type': yup.string().required(),
        'Docks Open on the following Days': yup.array()
    })
});

export const onlyGovernmentSchemaSPAYes = yup.object({
    Billing: yup.string().required(),
    Opportunity: yup.object({
        'Dealer ID': yup.string().required()
    }),
    'Order Information': yup.object({
        'PO Number': yup.string().required(),
        'Order Contact First Name': yup.string().required(),
        'Order Contact Last Name': yup.string().required(),
        'Order Contact Country Code': yup.string().required(),
        'Order Contact Phone': yup.string().required(),
        'Order Contact Email': yup.string().required(),
        'Dealer Sales Person Country Code': yup.string().required(),
        'Dealer Sales Person Phone': yup.string().required(),
        'Dealer Sales Person First Name': yup.string().required(),
        'Dealer Sales Person Last Name': yup.string().required(),
        'Dealer Sales Person Email': yup.string().required()
    }),
    'Shipping Information': yup.object({
        City: yup.string().required(),
        State: yup.string().required(),
        Country: yup.string().required(),
        'Company Name': yup.string().required(),
        'Address 1': yup.string().required(),
        'Postal Code': yup.string().required(),
        'Delivery Contact 1 First Name': yup.string().required(),
        'Delivery Contact 1 Country Code': yup.string().required(),
        'Delivery Contact 1 Phone': yup.string().required(),
        'Delivery Contact 1 Email': yup.string().required(),
        'Delivery Contact 2 First Name': yup.string().required(),
        'Delivery Contact 2 Country Code': yup.string().required(),
        'Delivery Contact 2 Phone': yup.string().required(),
        'Delivery Contact 2 Email': yup.string().required(),
        'Destination Type': yup.string().required(),
        'Docks Open on the following Days': yup.array()
    })
});

export const onlyGovernmentSchemaSPAExpired = yup.object({
    Billing: yup.string().required(),
    Opportunity: yup.object({
        'Dealer ID': yup.string().required()
    }),
    'Order Information': yup.object({
        'PO Number': yup.string().required(),
        'Order Contact First Name': yup.string().required(),
        'Order Contact Last Name': yup.string().required(),
        'Order Contact Country Code': yup.string().required(),
        'Order Contact Phone': yup.string().required(),
        'Order Contact Email': yup.string().required(),
        'Dealer Sales Person Country Code': yup.string().required(),
        'Dealer Sales Person Phone': yup.string().required(),
        'Dealer Sales Person First Name': yup.string().required(),
        'Dealer Sales Person Last Name': yup.string().required(),
        'Dealer Sales Person Email': yup.string().required()
    }),
    'Shipping Information': yup.object({
        City: yup.string().required(),
        State: yup.string().required(),
        Country: yup.string().required(),
        'Company Name': yup.string().required(),
        'Address 1': yup.string().required(),
        'Postal Code': yup.string().required(),
        'Delivery Contact 1 First Name': yup.string().required(),
        'Delivery Contact 1 Country Code': yup.string().required(),
        'Delivery Contact 1 Phone': yup.string().required(),
        'Delivery Contact 1 Email': yup.string().required(),
        'Delivery Contact 2 First Name': yup.string().required(),
        'Delivery Contact 2 Country Code': yup.string().required(),
        'Delivery Contact 2 Phone': yup.string().required(),
        'Delivery Contact 2 Email': yup.string().required(),
        'Destination Type': yup.string().required(),
        'Docks Open on the following Days': yup.array()
    })
});

export const governmentWithAddOnSchema = yup.object({
    'Sales Order': yup.object({
        'Original Sales Order #': yup.string().required()
    }),
    'Order Information': yup.object({
        'PO Number': yup.string().required(),
        'Order Contact First Name': yup.string().required(),
        'Order Contact Last Name': yup.string().required(),
        'Order Contact Country Code': yup.string().required(),
        'Order Contact Phone': yup.string().required(),
        'Order Contact Email': yup.string().required(),
        'Dealer Sales Person Country Code': yup.string().required(),
        'Dealer Sales Person Phone': yup.string().required(),
        'Dealer Sales Person First Name': yup.string().required(),
        'Dealer Sales Person Last Name': yup.string().required(),
        'Dealer Sales Person Email': yup.string().required()
    }),
    'Shipping Information': yup.object({
        City: yup.string().required(),
        State: yup.string().required(),
        Country: yup.string().required(),
        'Company Name': yup.string().required(),
        'Address 1': yup.string().required(),
        'Postal Code': yup.string().required(),
        'Delivery Contact 1 First Name': yup.string().required(),
        'Delivery Contact 1 Country Code': yup.string().required(),
        'Delivery Contact 1 Phone': yup.string().required(),
        'Delivery Contact 1 Email': yup.string().required(),
        'Delivery Contact 2 First Name': yup.string().required(),
        'Delivery Contact 2 Country Code': yup.string().required(),
        'Delivery Contact 2 Phone': yup.string().required(),
        'Delivery Contact 2 Email': yup.string().required(),
        'Destination Type': yup.string().required(),
        'Docks Open on the following Days': yup.array()
    })
});

export const governmentWithPunchSchema = yup.object({
    'Sales Order': yup.object({
        'Original Sales Order #': yup.string().required()
    }),
    'Order Information': yup.object({
        'PO Number': yup.string().required(),
        'Order Contact First Name': yup.string().required(),
        'Order Contact Last Name': yup.string().required(),
        'Order Contact Country Code': yup.string().required(),
        'Order Contact Phone': yup.string().required(),
        'Order Contact Email': yup.string().required(),
        'Dealer Sales Person Country Code': yup.string().required(),
        'Dealer Sales Person Phone': yup.string().required(),
        'Dealer Sales Person First Name': yup.string().required(),
        'Dealer Sales Person Last Name': yup.string().required(),
        'Dealer Sales Person Email': yup.string().required()
    }),
    'Shipping Information': yup.object({
        City: yup.string().required(),
        State: yup.string().required(),
        Country: yup.string().required(),
        'Company Name': yup.string().required(),
        'Address 1': yup.string().required(),
        'Postal Code': yup.string().required(),
        'Delivery Contact 1 First Name': yup.string().required(),
        'Delivery Contact 1 Country Code': yup.string().required(),
        'Delivery Contact 1 Phone': yup.string().required(),
        'Delivery Contact 1 Email': yup.string().required(),
        'Delivery Contact 2 First Name': yup.string().required(),
        'Delivery Contact 2 Country Code': yup.string().required(),
        'Delivery Contact 2 Phone': yup.string().required(),
        'Delivery Contact 2 Email': yup.string().required(),
        'Destination Type': yup.string().required(),
        'Docks Open on the following Days': yup.array()
    })
});

export const countryListConfig: AxiosRequestConfig = {
    method: 'get',
    url: 'https://api.countrystatecity.in/v1/countries',
    headers: {
        'X-CSCAPI-KEY': 'ck9wMDhzTlU3QWdEQUhLd1d3MlgxUG9mQXhTMm9UNkpqa2NOWExaRA=='
    }
};

export const stateListByCountryConfig = (countryISO: string): AxiosRequestConfig => ({
    method: 'get',
    url: ` https://api.countrystatecity.in/v1/countries/${countryISO}/states`,
    headers: {
        'X-CSCAPI-KEY': 'ck9wMDhzTlU3QWdEQUhLd1d3MlgxUG9mQXhTMm9UNkpqa2NOWExaRA=='
    }
});

const phoneCountries = [
    ...CountryList.getAll().filter((el) => ['United States'].includes(el.name)),
    ...CountryList.getAll().filter((el) => !['United States', 'Canada'].includes(el.name))
];

export const phoneCountryOptions = phoneCountries.map((option) => ({
    id: option.dialCode,
    key: `${option.code}-${option.dialCode}-${option.name}${option.name === 'United States' ? '-Canada' : ''}`,
    name: option.dialCode,
    renderName: (
        <>
            <Box
                sx={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    '& > svg': { height: '20px', marginRight: '5px' }
                }}
                dangerouslySetInnerHTML={{
                    __html: CountryFlagSvg[option.code]
                }}
            />
            <Typography variant="body1" fontSize={14} fontWeight={700}>
                &nbsp; {option.dialCode}
            </Typography>

            <Typography variant="body1" fontSize={14}>
                &nbsp; - &nbsp; {option.name === 'United States' ? 'United States / Canada' : option.name}
            </Typography>
        </>
    ),
    valuesToFilter: [option.name]
}));

export const selectedOptionRenderer = (code: string) => {
    const option = phoneCountries.find((el) => el.dialCode === code);

    if (!option) return null;
    return (
        <>
            <Box
                sx={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    '& > svg': { height: '20px', marginRight: '5px' },
                    ml: '12px',
                    mt: '5px'
                }}
                dangerouslySetInnerHTML={{
                    __html: CountryFlagSvg[option.code]
                }}
            />
            <Typography variant="body1" fontSize={14} fontWeight={400} sx={{ mt: '5px' }}>
                &nbsp; {option.dialCode}
            </Typography>
        </>
    );
};
