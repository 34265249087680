import { Close } from '@mui/icons-material';
import { Box, Checkbox, IconButton, InputAdornment, MenuItem, Popover, TextField, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';

export type ListProps = {
    open: boolean;
    anchorEl: HTMLElement | null;
    searchInputRef: React.RefObject<HTMLInputElement | null>;
    containerRef: React.RefObject<HTMLDivElement | null>;
    searchText: string;
    options: any[];
    filteredOptions: any[];
    selectedOptions: number[];
    allSelected: boolean;
    onClose: () => void;
    onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSelectOption: (selectedId: number[]) => () => void;
    onClearSearch: () => void;
    onSelectAll: () => void;
    onUnselectAll: () => void;
};

export const List = ({
    open,
    anchorEl,
    searchInputRef,
    containerRef,
    searchText,
    options,
    filteredOptions,
    selectedOptions,
    allSelected,
    onClose,
    onChangeSearch,
    onSelectOption,
    onClearSearch,
    onSelectAll,
    onUnselectAll
}: ListProps) => (
    <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ sx: { borderRadius: '8px', ml: '7px', mt: '5px', width: containerRef.current?.clientWidth || '200px' } }}
    >
        <PerfectScrollbar style={{ maxHeight: '500px' }}>
            {selectedOptions.length > 0 &&
                options
                    .filter((el) => selectedOptions.includes(el.id))
                    .map((el) => (
                        <Tooltip title={el.label}>
                            <Box>
                                <MenuItem
                                    sx={{ height: '40px', px: '12px', bgcolor: '#FFF' }}
                                    key={el.id}
                                    onClick={onSelectOption(selectedOptions.filter((opt) => opt !== el.id))}
                                >
                                    <>
                                        <Checkbox checked />
                                        <Typography fontSize="14px" sx={{ overflow: 'hidden', width: '100%', textOverflow: 'ellipsis' }}>
                                            {el.label}
                                        </Typography>
                                    </>
                                </MenuItem>
                            </Box>
                        </Tooltip>
                    ))}
            <TextField
                variant="filled"
                size="small"
                inputRef={searchInputRef}
                InputProps={{
                    hiddenLabel: true,
                    disableUnderline: true,
                    sx: { bgcolor: '#F5F6F7' },
                    endAdornment: searchText.length ? (
                        <InputAdornment position="end">
                            <IconButton aria-label="clear search" onClick={onClearSearch} edge="end">
                                <Close />
                            </IconButton>
                        </InputAdornment>
                    ) : undefined
                }}
                value={searchText}
                onChange={onChangeSearch}
                autoFocus
                fullWidth
            />
            {/* <MenuItem
                sx={{ border: 'solid 1px #D9D9D9', height: '40px', px: '12px', bgcolor: '#FFF' }}
                onClick={allSelected ? onUnselectAll : onSelectAll}
            >
                <>
                    <Checkbox checked={allSelected} />
                    <Typography fontSize="14px" sx={{ overflow: 'hidden', width: '100%', textOverflow: 'ellipsis' }}>
                        {allSelected ? 'Unselect All' : 'Select All'}
                    </Typography>
                </>
            </MenuItem> */}
            {filteredOptions.length ? (
                filteredOptions
                    .filter((el) => !selectedOptions.includes(el.id))
                    .map((el) => (
                        <Tooltip title={el.label}>
                            <Box>
                                <MenuItem
                                    sx={{ height: '40px', px: '12px', bgcolor: '#F5F6F7' }}
                                    key={el.id}
                                    onClick={onSelectOption([...selectedOptions, el.id])}
                                >
                                    <>
                                        <Checkbox checked={false} />
                                        <Typography fontSize="14px" sx={{ overflow: 'hidden', width: '100%', textOverflow: 'ellipsis' }}>
                                            {el.label}
                                        </Typography>
                                    </>
                                </MenuItem>
                            </Box>
                        </Tooltip>
                    ))
            ) : (
                <Typography fontSize="14px" sx={{ py: 2, px: 1 }} color="#C6C4C4" align="center">
                    <FormattedMessage id="noOptionsAvailable" />
                </Typography>
            )}
        </PerfectScrollbar>
    </Popover>
);
