import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import RenderModule from 'views/RenderModule';

// sample page routing

const CreateRecord = Loadable(lazy(() => import('views/CreateRecord')));
const DocumentViewer = Loadable(lazy(() => import('views/DocumentViewer')));
const DashboardPage = Loadable(lazy(() => import('views/dashboard')));
const RecordDetailsViewer = Loadable(lazy(() => import('views/RecordDetailsViewer')));
const SalesOrderDetailsViewer = Loadable(lazy(() => import('views/SalesOrderView')));
const PurchaseOrderDetailsViewer = Loadable(lazy(() => import('views/PurchaseOrderView')));
const DataFocusView = Loadable(lazy(() => import('views/DataFocusView')));
const DynamicPortals = Loadable(lazy(() => import('views/DynamicPortals')));

const ORDERBAHN_ROUTES = [
    {
        path: 'accounting/doc-viewer',
        element: <DocumentViewer />
    },
    {
        path: 'create-record',
        element: <CreateRecord />
    },
    {
        path: 'dashboard',
        element: <DashboardPage />
    },
    {
        path: 'data-focus-view',
        element: <DataFocusView />
    },
    {
        path: 'dynamic-portals/*',
        element: <DynamicPortals />
    },
    {
        path: 'record-details-viewer',
        element: <RecordDetailsViewer />
    },
    {
        path: 'sales-details-viewer',
        element: <SalesOrderDetailsViewer />
    },
    {
        path: 'pucharse-details-viewer',
        element: <PurchaseOrderDetailsViewer />
    },
    {
        path: '*',
        element: <RenderModule />
    }
];

const MainRoutes = {
    path: '/:tenantName',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: ORDERBAHN_ROUTES
    // IMPORTANT: This setting and also the imports below need to be restored if we want to show the Poet routes again
    // children: isPoetProject() ? POET_ROUTES : ORDERBAHN_ROUTES
};

export default MainRoutes;

// poet pages
// import { isPoetProject } from 'utils';
// import Channels from 'views/poet/Channels';
// import Settings from 'views/poet/Settings';
// const PoetProducts = Loadable(lazy(() => import('views/poet/products/index')));
// const PoetProductDetails = Loadable(lazy(() => import('views/poet/productDetails/index')));
// const PoetImportCsv = Loadable(lazy(() => import('views/poet/importCsv/index')));

// const POET_ROUTES = [
//     {
//         path: 'import',
//         element: <PoetImportCsv />
//     },
//     {
//         path: 'products/:sku',
//         element: <PoetProductDetails />
//     },
//     {
//         path: 'products',
//         element: <PoetProducts />
//     },
//     {
//         path: 'channels',
//         element: <Channels />
//     },
//     {
//         path: 'settings',
//         element: <Settings />
//     },
//     {
//         path: '*',
//         element: <div>Not found!</div>
//     }
// ];
