import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, IconButton, SxProps, Typography } from '@mui/material';
import { ChangeEvent, MouseEvent, ReactNode, useRef, useState } from 'react';
import { ObjectListAutocompleteOption } from './types';
import { List, ObjectListContainer } from './components';
import { SystemStyleObject } from '@mui/system';

export type ObjectListAutocompleteProps = {
    options: ObjectListAutocompleteOption[];
    value: number;
    onChange: (newId: number) => void;
    onClickSelectedOption: () => void;
    leftButtonLabel?: ReactNode;
    width?: string;
    sx?: SxProps;
    containerSx?: SystemStyleObject;
    label?: JSX.Element;
    variant?: 'outlined' | 'standalone' | 'focusview';
    noMargin?: boolean;
};

export const ObjectListAutocomplete = ({
    options,
    value,
    onChange,
    onClickSelectedOption,
    leftButtonLabel,
    width,
    sx,
    containerSx,
    label,
    variant = 'standalone',
    noMargin
}: ObjectListAutocompleteProps) => {
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchText, setSearchText] = useState('');

    const filteredOptions = options.filter((el) => el.enabled || el.id === value).filter((el) => el.label.includes(searchText));
    const open = Boolean(anchorEl);

    const selectedOption = value ? options.find((el) => el.id === value) || { label: '', id: undefined } : null;

    const handleOpenOptions = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setTimeout(() => {
            if (searchInputRef.current) searchInputRef.current.focus();
        }, 100);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const newVal = e.target.value;
        setSearchText(newVal);
    };

    const handleSelectOption = (selectedId: number) => () => {
        onChange(selectedId);
        handleClose();
    };

    return variant === 'outlined' || variant === 'focusview' ? (
        <ObjectListContainer
            label={label}
            ref={containerRef}
            open={open}
            sx={{
                mt: noMargin ? 0 : undefined,
                mx: noMargin ? 0 : undefined,
                width: variant === 'focusview' ? width : '91%',
                height: variant === 'focusview' ? '38px' : '50px',
                ...(containerSx || {})
            }}
        >
            <Box
                ref={containerRef}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: width || '200px',
                    height: '100%',
                    ...sx
                }}
            >
                {selectedOption ? (
                    <Typography
                        color="#37326E"
                        fontSize="14px"
                        onClick={value ? onClickSelectedOption : undefined}
                        sx={{
                            cursor: value ? 'pointer' : 'auto',
                            maxHeight: '100%',
                            flexGrow: 1,
                            overflow: 'hidden',
                            textDecoration: 'underline',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {selectedOption.label}
                    </Typography>
                ) : (
                    leftButtonLabel
                )}
                {selectedOption && (
                    <IconButton disableRipple onClick={handleSelectOption(0)} sx={{ ml: 'auto', pr: 0 }}>
                        <Close htmlColor="#54595E" />
                    </IconButton>
                )}
                <IconButton disableRipple onClick={handleOpenOptions} sx={{ ml: 'auto', px: 0 }}>
                    {open ? <ExpandLess htmlColor="#54595E" /> : <ExpandMore htmlColor="#54595E" />}
                </IconButton>
                <List
                    open={open}
                    anchorEl={anchorEl}
                    searchInputRef={searchInputRef}
                    containerRef={containerRef}
                    searchText={searchText}
                    options={filteredOptions}
                    onClose={handleClose}
                    onChangeSearch={handleChangeSearch}
                    onSelectOption={handleSelectOption}
                    onClearSearch={() => setSearchText('')}
                />
            </Box>
        </ObjectListContainer>
    ) : (
        <Box
            ref={containerRef}
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: width || '200px',
                height: '40px',
                ...sx
            }}
        >
            {selectedOption ? (
                <Typography
                    color="#37326E"
                    fontSize="14px"
                    onClick={value ? onClickSelectedOption : undefined}
                    sx={{
                        cursor: value ? 'pointer' : 'auto',
                        maxHeight: '100%',
                        flexGrow: 1,
                        overflow: 'hidden',
                        textDecoration: 'underline',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {selectedOption.label}
                </Typography>
            ) : (
                leftButtonLabel
            )}
            <IconButton disableRipple onClick={handleOpenOptions} sx={{ ml: 'auto' }}>
                {open ? <ExpandLess htmlColor="#54595E" /> : <ExpandMore htmlColor="#54595E" />}
            </IconButton>
            <List
                open={open}
                anchorEl={anchorEl}
                searchInputRef={searchInputRef}
                containerRef={containerRef}
                searchText={searchText}
                options={filteredOptions}
                onClose={handleClose}
                onChangeSearch={handleChangeSearch}
                onSelectOption={handleSelectOption}
                onClearSearch={() => setSearchText('')}
            />
        </Box>
    );
};
