export const getRecordViewerURL = (recordId: string, type: string): string => {
    const tenantUrl = localStorage.getItem('tenantPath');
    if (!tenantUrl) return '';
    return window.location.host.includes('localhost')
        ? `${window.location.host}${tenantUrl}/accounting/doc-viewer/?recordId=${recordId}&recordType=${type}`
        : `https://${window.location.host}${tenantUrl}/accounting/doc-viewer/?recordId=${recordId}&recordType=${type}`;
};

export const getDataFocusViewURL = (recordId: string, type: string): string => {
    const tenantUrl = localStorage.getItem('tenantPath');
    if (!tenantUrl) return '';
    return window.location.host.includes('localhost')
        ? `${window.location.host}${tenantUrl}/data-focus-view/?recordId=${recordId}&recordType=${type}`
        : `https://${window.location.host}${tenantUrl}/data-focus-view/?recordId=${recordId}&recordType=${type}`;
};
