import React, { forwardRef, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { Box, TextField } from '@mui/material';
import { GridSingleSelect } from 'ui-component/formComponents';
import { ArrowDropDown } from '@mui/icons-material';
import { phoneCountryOptions } from 'views/Portal/Tabs/Request/assets';
import { CustomProps, EventTarget, InputMaskProps } from './InputMaskPhone';

const TextMaskCustom = forwardRef<HTMLElement, CustomProps>((props, ref) => {
    const { onChange, ...other } = props;

    return (
        <IMaskInput
            {...other}
            mask="(#00) 000-0000"
            definitions={{
                '#': /[1-9]/
            }}
            inputRef={ref as React.Ref<HTMLInputElement>}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

const SEPARATOR = ',';

const InputMaskPhone: React.FC<InputMaskProps> = (props) => {
    const eventTargetRef = React.useRef<EventTarget>({} as EventTarget);
    const { name, onChange, options, value, ...other } = props;
    // eslint-disable-next-line no-useless-escape
    const valueWithoutBrackets = value.replace(/[\[\]]/g, '');

    const [selectedCountry, setSelectedCountry] = useState<string | number>(
        valueWithoutBrackets.includes(SEPARATOR) ? valueWithoutBrackets.split(SEPARATOR)[0] : ''
    );

    const handleCountryChange = (valuePhone: string | number) => {
        const valuePhoneStr = String(valuePhone);
        const valuePhoneWithoutPlus = valuePhoneStr.replace(/\+/g, '');

        if (Object.keys(eventTargetRef.current).length > 0 && value !== selectedCountry) {
            const beforeValue = eventTargetRef.current.target.value.includes(SEPARATOR)
                ? eventTargetRef.current.target.value.split(SEPARATOR)[1]
                : eventTargetRef.current.target.value;
            // eslint-disable-next-line no-useless-escape
            const beforeValueWithoutBrackets = beforeValue.replace(/[\[\]]/g, '');

            onChange({
                target: {
                    ...eventTargetRef.current.target,
                    value: `[${valuePhoneWithoutPlus}${SEPARATOR}${beforeValueWithoutBrackets}]`
                }
            });
        }

        setSelectedCountry(valuePhoneWithoutPlus);
    };

    const handleInputChange = (event: EventTarget) => {
        const newValue = event.target.value.includes(SEPARATOR) ? event.target.value.split(SEPARATOR)[1] : event.target.value;
        const cleanedValue = newValue.replace(/[()\-\s]/g, '');

        if (cleanedValue.length > 0) {
            event.target = {
                ...event.target,
                value: `[${selectedCountry}${SEPARATOR}${cleanedValue}]`
            };
            eventTargetRef.current = event;
        }
        onChange(event);
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ bgcolor: '#F5F6F7', border: 'none', width: '100%' }}>
            <GridSingleSelect
                width="100px"
                onChange={handleCountryChange}
                options={options || phoneCountryOptions}
                listFitContent
                value={`+${selectedCountry}`}
                leftIcon={<ArrowDropDown />}
                sx={{ bgcolor: '#F5F6F7', height: 35 }}
            />
            <TextField
                {...other}
                name={name}
                onChange={handleInputChange}
                value={valueWithoutBrackets.includes(SEPARATOR) ? valueWithoutBrackets.split(SEPARATOR)[1] : valueWithoutBrackets}
                InputProps={{
                    inputComponent: TextMaskCustom as any
                }}
                sx={{
                    bgcolor: '#F5F6F7',
                    width: '100%',
                    '& .MuiInputBase-root input': {
                        width: '100%'
                    },
                    '& .MuiInputBase-root': {
                        borderRadius: '4px',
                        padding: '4px'
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 'none'
                    },
                    '& .MuiInput-underline:after': {
                        borderBottom: 'none'
                    },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                        borderBottom: 'none'
                    }
                }}
            />
        </Box>
    );
};

export default InputMaskPhone;
