import React, { useState, useEffect } from 'react';
import { TextField, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box } from '@mui/system';
import { useIntl } from 'react-intl';

export interface EmailEditCellProps {
    onChange: (value: string) => void;
    value?: string;
}

export const EmailEditCell = ({ onChange, value }: EmailEditCellProps) => {
    const theme = useTheme();
    const [error, setError] = useState<string | null>(null);
    const intl = useIntl();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(newValue)) {
            setError(intl.formatMessage({ id: 'invalidEmail' }));
        } else {
            setError(null);
        }

        onChange(newValue);
    };

    useEffect(() => {
        if (value === '') {
            setError(null);
        }
    }, [value]);

    return (
        <Box position="relative" sx={{ width: '100%' }}>
            <TextField
                value={value}
                onChange={handleChange}
                sx={{
                    width: '100%',
                    '& .MuiInputBase-root input': {
                        backgroundColor: `${error ? theme.palette.error.light : theme.palette.action.hover} !important`,
                        width: '100%'
                    }
                }}
                className="error"
            />
            {error && (
                <Box position="absolute" right={0} top="30%" sx={{ transform: 'translateY(-50%)' }} mr={0.5}>
                    <Tooltip title={error} placement="top">
                        <InfoOutlinedIcon
                            sx={{
                                fontSize: '1rem',
                                color: '#858585',
                                cursor: 'pointer'
                            }}
                        />
                    </Tooltip>
                </Box>
            )}
        </Box>
    );
};
