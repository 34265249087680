import React, { useEffect, useState } from 'react';
import {
    Box,
    Collapse,
    FormControl,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    NativeSelect,
    PopoverOrigin,
    Select,
    SelectChangeEvent,
    SxProps,
    Theme
} from '@mui/material';
import { ArrowDropDown, Clear } from '@mui/icons-material';
import { GridSingleSelect } from 'ui-component/formComponents';
import { ListValue } from 'views/backoffice/CustomLists/types';

export interface SingleSelectDropdownProps {
    onChange: (value: string | string[]) => void;
    initialValue?: string | string[];
    label?: string;
    native?: boolean;
    fromFilterPanel?: boolean;
    multiple?: boolean;
    PopoverSx?: SxProps<Theme> | undefined;
    PopoverPropsAnchorOrigin?: PopoverOrigin | undefined;
    PopoverPropsTransformOrigin?: PopoverOrigin | undefined;
    listValues?: ListValue[];
}

export const SingleSelectDropdown = ({
    onChange,
    initialValue,
    native,
    label,
    fromFilterPanel,
    multiple,
    PopoverSx,
    PopoverPropsAnchorOrigin,
    PopoverPropsTransformOrigin,
    listValues
}: SingleSelectDropdownProps) => {
    const [optionSelected, setOptionSelected] = useState<string>('');
    const [optionsSelected, setOptionsSelected] = useState<string[]>([]);

    useEffect(() => {
        if (initialValue) {
            if (multiple) setOptionsSelected(initialValue as string[]);
            else setOptionSelected(initialValue as string);
        }
    }, [initialValue, multiple]);

    const handleValueChange = (event: SelectChangeEvent<string | string[]> | React.ChangeEvent<{ value: string }>) => {
        const newValue = event.target.value;
        if (multiple) setOptionsSelected(newValue as string[]);
        else setOptionSelected(newValue as string);
        onChange(newValue);
    };

    const handleClearClick = () => {
        const newValue = ['0'];
        setOptionsSelected(newValue as string[]);
        onChange(newValue);
    };

    return (
        <Box sx={{ minWidth: 149, width: '100%' }}>
            <FormControl fullWidth>
                {label && (
                    <InputLabel id="record-status-select" shrink>
                        {label}
                    </InputLabel>
                )}

                {fromFilterPanel && multiple && (
                    <Select
                        value={optionsSelected}
                        onChange={handleValueChange}
                        multiple
                        sx={{ pt: '3px !important' }}
                        endAdornment={
                            <IconButton
                                sx={{
                                    display: 'none'
                                }}
                                onClick={handleClearClick}
                            >
                                <Clear />
                            </IconButton>
                        }
                    >
                        {listValues?.map((el) => (
                            <MenuItem key={el.id} value={el.id}>
                                {el.value}
                            </MenuItem>
                        ))}
                    </Select>
                )}

                {fromFilterPanel && native && (
                    <NativeSelect value={optionSelected} onChange={handleValueChange}>
                        <option value=""> &nbsp;</option>
                        {listValues?.map((el) => (
                            <option key={el.id} value={el.value}>
                                {el.value}
                            </option>
                        ))}
                    </NativeSelect>
                )}

                {!fromFilterPanel && (
                    <GridSingleSelect
                        width="100%"
                        onChange={(value) => {
                            handleValueChange({ target: { value: String(value) } } as SelectChangeEvent<string | string[]>);
                        }}
                        options={
                            listValues
                                ?.filter((el) => el.enabled)
                                .map((el) => ({
                                    id: el.id,
                                    name: el.value
                                })) || []
                        }
                        // listFitContent
                        value={listValues?.find((el) => el.value === optionSelected || Number(el.id) === Number(optionSelected))?.id || ''}
                        leftIcon={<ArrowDropDown />}
                        sx={{
                            borderRadius: '8px',
                            '&:hover': { bgcolor: '#EBEEFE' },
                            bgcolor: '#F5F6F7'
                        }}
                        PopoverSx={PopoverSx}
                        PopoverPropsAnchorOrigin={PopoverPropsAnchorOrigin}
                        PopoverPropsTransformOrigin={PopoverPropsTransformOrigin}
                    />
                )}
                <Collapse>
                    <LinearProgress />
                </Collapse>
            </FormControl>
        </Box>
    );
};
